<template>

  <div class="modal-wrapper" id="team-component" style="display: none">

    <h1 class="modal-title">
      Team
    </h1>

    <div class="modal-wrapper-content">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Enim neque volutpat ac tincidunt vitae. Non diam
        phasellus vestibulum lorem sed risus ultricies. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Nisi vitae suscipit tellus mauris
        a
        diam maecenas sed enim. Pellentesque habitant morbi tristique senectus. Bibendum at varius vel pharetra vel turpis nunc eget. Condimentum lacinia quis vel eros donec ac odio. Ac turpis egestas maecenas pharetra
        convallis.
      </p>
      <p>
        Quam viverra orci sagittis eu volutpat odio. Urna id volutpat lacus laoreet non curabitur gravida arcu. Sed adipiscing diam donec adipiscing tristique risus. Nullam vehicula ipsum a arcu cursus vitae congue
        mauris rhoncus. Faucibus nisl tincidunt eget nullam non nisi est sit. Dui accumsan sit amet nulla facilisi morbi. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. Neque egestas congue
        quisque egestas diam in arcu cursus. Vivamus arcu felis bibendum ut tristique. Lacus laoreet non curabitur gravida. Blandit cursus risus at ultrices mi tempus imperdiet. Faucibus scelerisque eleifend donec
        pretium vulputate. Tellus in metus vulputate eu scelerisque felis. Non quam lacus suspendisse faucibus. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Non blandit massa enim nec dui.
        Tellus in metus vulputate eu scelerisque felis imperdiet proin. Mauris ultrices eros in cursus.
      </p>
      <p>
        Porttitor lacus luctus accumsan tortor posuere ac ut. Id interdum velit laoreet id. Ultrices neque ornare aenean euismod elementum nisi quis eleifend quam. Orci phasellus egestas tellus rutrum. Platea dictumst
        vestibulum rhoncus est pellentesque. Orci dapibus ultrices in iaculis nunc sed. Pharetra pharetra massa massa ultricies mi quis. Lectus magna fringilla urna porttitor rhoncus dolor. Auctor augue mauris augue
        neque gravida in fermentum et. Ornare quam viverra orci sagittis. Sit amet nisl suscipit adipiscing bibendum.
      </p>
      <p>
        Lorem mollis aliquam ut porttitor leo a diam sollicitudin. Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique. Blandit cursus risus at ultrices mi tempus. Eget duis at tellus at
        urna
        condimentum mattis. Morbi quis commodo odio aenean sed adipiscing. Auctor augue mauris augue neque gravida in. Faucibus turpis in eu mi bibendum neque egestas. Pellentesque nec nam aliquam sem et tortor
        consequat. Elementum curabitur vitae nunc sed velit dignissim sodales ut eu. Ultricies leo integer malesuada nunc vel risus commodo viverra. Felis donec et odio pellentesque diam volutpat commodo. In iaculis
        nunc
        sed augue. Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Sed nisi lacus sed viverra tellus in hac habitasse. Ac ut consequat semper viverra nam libero justo laoreet. Diam sit
        amet nisl suscipit adipiscing.
      </p>
      <p>
        Vel pharetra vel turpis nunc eget lorem dolor. Euismod nisi porta lorem mollis aliquam. Eu feugiat pretium nibh ipsum consequat. Odio ut enim blandit volutpat. Eget nullam non nisi est sit amet facilisis.
        Facilisis gravida neque convallis a cras semper. Vitae auctor eu augue ut lectus arcu. Malesuada fames ac turpis egestas integer eget. Magna sit amet purus gravida quis blandit turpis cursus in. Bibendum at
        varius vel pharetra vel turpis nunc eget.
      </p>
    </div>

  </div>

</template>

<script>
export default {
  name: 'Team-Component'
}
</script>
