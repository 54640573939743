<script>
// eslint-disable-next-line no-unused-vars
import {Fancybox, Carousel, Panzoom} from "@fancyapps/ui";
import ProjectComponent from './components/project-component.vue'
import TeamComponent from './components/team-component.vue'

import "./styles/main.scss";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  name: 'App',
  components: {
    ProjectComponent,
    TeamComponent
  }
}
</script>

<template>

  <div class="main-menu">
    <div class="menu-item" data-fancybox data-src="#team-component">
      Team
    </div>
    <div class="menu-logo">
      Meta<br>
      Phangan
    </div>
    <div class="menu-item" data-fancybox data-src="#project-component">
      Project
    </div>
  </div>

  <ProjectComponent/>
  <TeamComponent/>
</template>
